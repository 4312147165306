<template>
<div>
    <v-dialog v-model="$store.state.productDialog" :fullscreen="isfullscreen" width="70%" persistent
        transition="dialog-top-transition">
        <v-card flat class="card">
            <v-toolbar outlined flat :color="!$vuetify.theme.dark ? 'grey lighten-4' : ''">
                <v-toolbar-title>{{ $store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل' }}
                    {{ this.$route.meta.single }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="isfullscreen = !isfullscreen">
                    <v-icon small>{{ isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand" }}</v-icon>
                </v-btn>
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>
                            <v-row>
                                <!-- name -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2"> اسم المنتح <span class="required">*</span> </h4>
                                    <v-text-field v-model="item.name" placeholder="العلاكة البيضاء"
                                        :rules="[$global.state.required()]" required color="info" filled outlined
                                        hide-details="auto" />
                                </v-col>

                                <!-- description -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2"> الوصف المنتح </h4>
                                    <v-text-field v-model="item.description" placeholder="قدم وصفاً لمنتجك" required
                                        color="info" filled outlined hide-details="auto" />
                                </v-col>
                            </v-row>

                            <!-- sizes -->
                            <v-alert outlined dense class="my-6" color="primary" max-height="60vh"
                                style="overflow: auto;">
                                <div class="d-flex justify-space-between align-center secondary--text">
                                    <v-btn small class="btn py-5 secondary--text" color="primary" @click="addProduct()">
                                        <v-icon left small>mdi-plus-circle</v-icon>
                                        <h3>إضافة قياس</h3>
                                    </v-btn>
                                    <h5>معلومات القياسات</h5>
                                </div>

                                <v-card tile flat class="pa-4 pa-2 mt-5"
                                    :class="$vuetify.theme.dark ? 'grey darken-4 shadow' : 'grey lighten-4'"
                                    v-for="(product, index) in item.sizes" :key="index">
                                    <v-btn class="btn-remove-productSize" @click="remove(index)" color="error" icon
                                        small>
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>

                                    <v-row>

                                        <!-- name -->
                                        <v-col cols="12" md="3">
                                            <h5 class="mb-2"> القياس <span class="required">*</span> </h5>
                                            <v-text-field v-model="item.sizes[index].name" placeholder="قياس 20 * 15"
                                                :rules="[$global.state.required()]" required color="info" dense filled
                                                outlined hide-details="auto" />
                                        </v-col>

                                        <!-- minNoSale -->
                                        <v-col cols="12" md="3">
                                            <h5 class="mb-2">اقل كمية للبيع<span class="required">*</span> </h5>
                                            <v-text-field v-model="item.sizes[index].minNoSale" type="number"
                                                :min="1000" outlined dense filled color="info" hide-details="auto"
                                                required />
                                        </v-col>

                                        <!-- price -->
                                        <v-col cols="12" md="3">
                                            <h5 class="mb-2">
                                                سعر الـ
                                                {{ item.sizes ? item.sizes[index].minNoSale : '' }}
                                                قطعة
                                                <span class="required">*</span>
                                            </h5>
                                            <v-text-field v-model="item.sizes[index].price"
                                                @input="item.sizes[index].price = $genericService.formatCurrency(item.sizes[index].price.toString())"
                                                :rules="[$global.state.required()]" placeholder="0.0" suffix="IQD" dense
                                                filled outlined hide-details="auto" required />
                                        </v-col>

                                        <!-- discount -->
                                        <v-col cols="12" md="3">
                                            <h5 class="mb-2">الخصم ٪ <span class="required">*</span> </h5>
                                            <v-text-field v-model="item.sizes[index].discount"
                                                :rules="[$global.state.maxNumber(100), $global.state.minNumber(0)]"
                                                suffix="%" type="number" :min="0" :max="100" outlined dense filled
                                                color="info" hide-details="auto" required />
                                        </v-col>

                                        <!-- description -->
                                        <v-col cols="12" md="12">
                                            <h5 class="mb-2">وصف القياس</h5>
                                            <v-textarea v-model="item.sizes[index].description" required outlined dense
                                                filled rows="2" hide-details="auto"
                                                placeholder="يستخدم هذا القياس في الصيدليات" color="info"></v-textarea>
                                        </v-col>

                                    </v-row>
                                </v-card>
                            </v-alert>

                            <v-row>
                                <!-- attachments -->
                                <v-col cols="12" md="8">
                                    <h4 class="mb-2">صور المنتج</h4>
                                    <MultipartFileUpload accept="image/*;capture=camera" formData="files"
                                        endPoint="UploadFile/postUploadFiles" buttonText="رفع صـور للمادة"
                                        icon="true" />
                                </v-col>

                                <v-col cols="12" md="4">
                                    <h4 class="mb-2">الفيديوا التوضيحي للمنتج المنتج</h4>
                                    <VideoUpload accept="video/mp4,video/x-m4v,video/*" formData="files"
                                        endPoint="UploadFile/postUploadFiles" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>

                    <!-- actions -->
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :loading="$global.state.loading" x-large color="primary"
                                class="btn secondary--text" :disabled="!valid">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>

                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import axios from "../../../axios"
export default {
    components: {
        MultipartFileUpload: () => import("@/components/MultipartFileUpload.vue"),
        VideoUpload: () => import("@/components/VideoUpload.vue"),
    },

    data() {
        return {
            valid: false,
            isfullscreen: false,
            item: {},
        };
    },

    mounted() {
        this.reset();
        if (!this.$global.state.categories) this.getCategories();

        this.$eventBus.$on("fill-fields-product", () => {
            // // console.log(this.$store.state.itemDetails);
            this.item = this.$store.state.itemDetails;
            this.item.categoryId = this.$store.state.itemDetails.categoryId;

            if (this.$store.state.itemDetails.attachments) {

                this.$store.state.itemDetails.attachments.forEach(async (element) => {

                    this.$store.state.previews.push(element.path);
                    const path = element.path.replace(this.$store.state.url, "")
                    var fileResult = await axios.get("UploadFile?path=" + path);

                });
            }

            if (this.$store.state.itemDetails.videoUrl) this.$store.state.vedioPreviews.push(this.$store.state.itemDetails.videoUrl);

        });
    },

    methods: {
        reset() {
            this.item = {
                categoryId: this.$global.state.categoryId,
                name: "",
                description: "",
                videoUrl: "",
                attachments: [],
                sizes: [
                    {
                        name: "",
                        description: "",
                        price: "0",
                        discount: "0",
                        minNoSale: 1000,
                    },
                ],
            };
            this.$store.commit("clearFiles");
            this.$store.commit("clearVedioFiles");
        },

        submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                for (let i = 0; i < this.item.sizes.length; i++) {
                    this.item.sizes[i].price = typeof this.item.sizes[i].price == "string" ? parseFloat(this.item.sizes[i].price.replace(/,/g, "")) : this.item.sizes[i].price;
                }
                if (this.$store.state.itemDetails.dialogType == "add") {
                    this.addItem();
                } else {
                    this.editItem();
                }
            }
        },

        async addItem() {
            this.$global.state.loading = true;
            if (this.$store.state.files[0] != null) {
                this.$store.state.progress = true;
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        res.data.result.fileList.forEach((element) => {
                            this.item.attachments.push({
                                path: element.path,
                                name: element.name,
                                type: element.type,
                            });
                        });
                    })
                    .finally(() => (this.$global.state.loading = false));

            }
            if (this.$store.state.vedioFiles[0] != null) {
                this.$store.state.progress = true;
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.vedioFiles)) {
                    formData.append("files", this.$store.state.vedioFiles[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        this.item.videoUrl = res.data.result.fileList[0].path;
                    });
            }
            this.item.attachments[0].isMain = true;
            this.item.categoryId = this.$global.state.categoryId;
            await this.$http
                .post(`Products`, this.item)
                .then((res) => {
                    this.$eventBus.$emit(`refreshProducts`);
                    this.close();
                    this.$store.dispatch("toastification/setToast", { message: `${res.data.message}`, type: "success" });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", { message: `${err.data.message}`, type: "error" });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        async editItem() {
            this.$global.state.loading = true;
            // upload new files
            this.$global.state.loading = true;
            if (this.$store.state.files[0] != null) {
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        res.data.result.fileList.forEach((element) => {
                            this.item.attachments.push({
                                path: element.path,
                                name: element.name,
                                type: element.type,
                            });
                        });
                    }).finally(() => (this.$global.state.loading = false));

            }

            this.item.attachments.forEach((element) => {
                element.path = element.path.replace(this.$store.state.url, "");
            });

            // upload new vedio
            if (this.$store.state.vedioFiles[0] != null) {
                this.$store.state.progress = true;
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.vedioFiles)) {
                    formData.append("files", this.$store.state.vedioFiles[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        this.item.videoUrl = res.data.result.fileList[0].path;
                    });
            }

            this.$http
                .put(`Products/${this.item.id}`, this.item)
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                    this.$eventBus.$emit(`refresh`);
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        getCategories() {
            this.$global.dispatch(`getCategories`);
        },

        addProduct() {
            this.item.sizes.push({
                name: "",
                description: "",
                price: "0",
                discount: "0",
                minNoSale: "0",
            });
        },

        remove(index) {
            this.item.sizes.splice(index, 1);
        },

        close() {
            this.$store.commit("setProductDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
            // console.log("categoryId", this.$global.state.categoryId);
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>


<style scoped>
.btn-remove-productSize {
    position: absolute;
    top: 0px;
    left: 0px;
}
</style>
